/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Swiper from 'react-id-swiper'
import './blogPosts.css'
import 'swiper/css/swiper.css'
import shuffle from '../../utils/shuffle'

export default function SectionBlogPosts ({ data, title, callToAction, blog, path }) {
  // const filteredTitle = 'Outros Posts'
  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slidesPerView: 1,
    spaceBetween: 6,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 640px
      1023: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  }

  data = useStaticQuery(
    graphql`{
  blog: allContentfulBlog(sort: {fields: date, order: DESC}, limit: 6) {
    edges {
      node {
        title
        subtitle
        slug
        categoria
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
  )
  // eslint-disable-next-line no-unused-vars
  let cat = ''
  if (typeof blog !== 'undefined') {
    data.blog = blog
  }

  const _postagens = shuffle(data.blog.edges.filter(
    edge => edge.node.slug !== path
  ))

  // const _title = (typeof filteredTitle !== 'undefined' && data.blog.edges.length !== _postagens.length) ? filteredTitle : title

  return (
    <Fragment>
      {_postagens.length !== 0 &&
        <section id='blogPosts' className="container">
          <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
            {title === undefined
              ? <h2 className="d-flex flex-column flex-sm-row my-4 my-lg-5 theme-secundary">CONFIRA O <span className="theme-primary ml-sm-2">NOSSO BLOG</span></h2>
              : <h2 className="d-flex flex-column flex-sm-row my-4 my-lg-5 theme-secundary"><span className="theme-primary ml-sm-2">{title}</span></h2>}
          </div>
          <Swiper {...params}>
            {_postagens.map((post, index) => {
              if (post.node.categoria[0] === 'SÊNIOR') {
                cat = 'senior'
              }
              if (post.node.categoria[0] === 'MUNDO DA BELEZA') {
                cat = 'beleza'
              }
              if (post.node.categoria[0] === 'PAIS E FILHOS') {
                cat = 'pais-e-filhos'
              }
              return (
                <div key={index} className="card mb-4 box-shadow" style={{ whiteSpace: 'break-spaces' }}>
                  <GatsbyImage
                    image={post.node.image.localFile.childImageSharp.gatsbyImageData}
                    className="card-img-top"
                    alt={post.node.slug}
                    style={{ height: '200px' }} />
                  <div className="card-body ">
                    <h3 className="card-title">{post.node.title}</h3>
                    <p className="card-text text-secundary truncate-overflow">{post.node.subtitle}</p>
                  </div>
                  <div className="card-footer bg-transparent border-0">
                    <Link to={`/blog/${cat}/${post.node.slug}/`} className="btn-block btn btn-lg orange">Leia Mais</Link>
                  </div>
                </div>
              )
            })}
          </Swiper>
          <Link to="/blog/" className="d-flex justify-content-center align-items-center mt-sm-4 btn theme-border-2 theme--primary text-white w-100">
            Veja Mais Postagens
          </Link>
        </section>
      }
    </Fragment>
  )
}
