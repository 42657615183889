/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import * as styles from './carousel.module.scss'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

export default function BannerHome ({ banners }) {
  return (
    <Carousel showArrows={true} showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay={true}>
      {
        banners.nodes.map((banner, index) => {
          const sources = withArtDirection(getImage(banner.banner), [
            {
              media: '(max-width: 768px)',
              image: getImage(banner.mobileBanner)
            }
          ])
          return (
            <Link to={banner.url} key={index}>
              <div>
                <GatsbyImage image={sources} alt={banner.title} style={{ width: 100 + '%' }} className={styles.artDirected} />
              </div>
            </Link>
          )
        })
      }
    </Carousel>
  )
}
