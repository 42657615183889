import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Pagina from '../components/Pagina'
import '../assets/css/common.css'
import PageIndex from '../components/page-index/page-index'
import { Helmet } from 'react-helmet'

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
    }
  `
  )

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <Helmet>
        <meta name="google-site-verification" content="E3lFnt6I7OZ2XGVGBfZwiIV0-kaM9gfO2UD4ipFXtFY" />
      </Helmet>
      <PageIndex />
    </Fragment>
  )
}
export default IndexPage
