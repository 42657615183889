/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import axios from 'axios'

import { graphql, useStaticQuery } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'

/* COMPONENTS */
import Loading from '../Loader/loader'

export default function FormIndex () {
  const data = useStaticQuery(
    graphql`
      query {
        pagina: contentfulPagina(path: { eq: "/contato/" }) {
          path
          metaTitle
          metaDescription
          tags
        }
        cloudOfertasForm(name: {eq: "CONTATO"}) {
          id
          formTypeField {
            name
            type
            required
            style
            length
            label
            formLov {
              formLovData {
                id
                value
                createdDate
              }
            }
          }
        }
      }
    `
  )
  const urlApi = `${process.env.GATSBY_CLOUDOFERTAS_API_URL}/sites/${process.env.GATSBY_CLOUDOFERTAS_SITE_ID}/form-data/${data.cloudOfertasForm.id}/`
  const [loading, setLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)

  // const onSubmit = async (data) => {
  //   setDisableBtn(true)
  //   setLoading(true)
  //   try {
  //     const config = {
  //       headers: {
  //         accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
  //       }
  //     }

  //     const result = await axios.post(urlApi, data, config)
  //     setLoading(false)
  //     setSubmitSuccess(true)

  //     console.log('result:', result)
  //   } catch (err) {
  //     console.log('ERRO: ', err)
  //   }
  // }

  return (
    <Fragment>
      <Loading loader={loading}/>
      <section id="contact" className="theme--quaternary mt-5 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-flex contact-img mb-4">
            </div>
            <div className="col-12 col-lg-6 pl-lg-4">
              <h2 className="mt-4 mb-0 theme-secundary">ENTRE EM CONTATO <span className="theme-primary">CONOSCO</span></h2>
              <div>
                { data.cloudOfertasForm.formTypeField && submitSuccess === false &&
                  <RenderForm
                    disabledButton={disableBtn}
                    setSuccessSubmit={setSubmitSuccess}
                    formData={data.cloudOfertasForm}
                    // onSubmit={onSubmit}
                    placeholder={true}
                    containerStyle='container'
                    rowStyle='row'
                    inputStyle='custom-form d-flex flex-column col-lg-12 p-0 mt-3'
                    btnContainerStyle='custom-form col-12 p-0'
                    btnStyle='btn btn-block theme--secundary btn-outline-#1A3797 theme-border-2 text-white my-3'
                    btnName='ENVIAR'
                  />
                }
                {submitSuccess &&
                  <div id="alertSucesso" className="alert alert-success mt-4" role="alert">
                    Obrigado por entrar em contato com a gente. Responderemos em até 48h.
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
