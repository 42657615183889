import React, { Fragment } from 'react'
import BannerHome from '../../components/carousel/carousel'
import FormIndex from '../../components/form-index/form-index'
import OffersDay from '../../components/offers-day/offers-day'
// import Whatsapp from '../../assets/icons/wpp.svg'
import { graphql, useStaticQuery, Link } from 'gatsby'
import './page-index.css'
import SectionBlogPosts from '../SectionBlogPosts'
// import BannerCovid from '../banner-covid/banner-covid'

export default function PageIndex () {
  const data = useStaticQuery(
    graphql`
      query {
        Ofertas: allMysqlOfertas(sort: {fields: DataInicio, order: DESC}) {
          edges {
            node {
              Id
              Dia
              Imagens
              Formato
              Categoria
              DataInicio
              DataFinal
            }
          }
        }
        banners: allCloudOfertasBanner {
          nodes {
            url
            active
            target
            title
            mobileBanner {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            banner {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  )
  return (
    <Fragment>
      {/* <BannerCovid /> */}
      {/* <button className='d-flex flex-row-reverse botao-whats-fixo'>
        <div className='d-flex'>
  <img src={Whatsapp} alt='Whatsapp' className='botao-fixo-zap' />
  <p className='mt-3 texto-solicite-zap' style={{ position: 'relative', left: '-10px' }}>Solicite agora!</p>
  </div>
</button> */}

      <div className="modal fade show" id="modal" tabIndex="-1" role="dialog" aria-labelledby="modalCovid" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
        </div>
      </div>
      <section id="icon-main" className="d-none">
        <div className="container">
          <ul className="row justify-content-center flex-wrap list-inline py-2">
            <li><Link to="/lojas/" title="Ir para o localizador de lojas" className="btn text-white"><span className="sr-only">LOJAS</span></Link></li>
            <li><Link to="/lojas/" title="Ir para o localizador de lojas" className="btn text-white"><span className="sr-only">DISK ENTREGA</span></Link></li>
            <li><Link to="/ofertas/" className="btn text-white" title="Ir para a página de ofertas"><span className="sr-only">OFERTAS</span></Link></li>
            <li><Link to="/convenios-parcerias/" className="btn text-white" title="Ir para a página de convênios"><span className="sr-only">CONVÊNIOS</span></Link></li>
          </ul>
        </div>
      </section>
      <div>
        <BannerHome banners={data.banners} />
      </div>
      {data.Ofertas.edges.node !== null
        ? <OffersDay />
        : ''
      }
      <section className="d-none d-lg-flex">
        <div className="container">
          <div className="row">
            <div id="offers-day-desktop" className="col-12 d-flex flex-column justify-content-center align-items-center">
              <h2 className="theme-primary">Lojas</h2>
              <p className="theme-primary">Encontre uma Droga Leste perto de você!</p>
              <Link to="/lojas/" className="mb-4 d-flex flex-column justify-content-center btn theme-border-2 theme--secundary text-white" title="Buscar loja">BUSCAR LOJA</Link>
            </div>
            <div className="col-4 text-white text-center contact-us">
              <div></div>
              <h3>Disk entrega</h3>
              <p>A Droga Leste vai até você</p>
              <Link to="/lojas/" className="w-75 btn theme-border-2 theme--secundary text-white" title="Solicite agora a entrega em domicílio">SOLICITE AGORA</Link>
            </div>
            <div className="col-4 text-white text-center offers">
              <div></div>
              <h3>Ofertas</h3>
              <p>As melhores marcas por <br/> preços incríveis</p>
              <Link to="/ofertas/" className="w-75 btn theme-border-2 theme-secundary bg-white" title="Ver ofertas da loja">VER OFERTAS</Link>
            </div>
            <div className="col-4 text-white text-center covenant">
              <div></div>
              <h3>Convênio</h3>
              <p>A Droga Leste atende <br/> diversos planos e convênios</p>
              <Link to="/convenios-parcerias/" className="w-75 btn theme-border-2 theme--secundary text-white" title="seja um conveniado">SEJA UM CONVENIADO</Link>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="py-2">
        <div id="parallax" className="d-none d-lg-flex mt-5 bgParallax" data-speed="100" style={{ backgroundPosition: '2% -11.128px' }}></div>
        <div className="container">
          <div className="row">
            <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
              <h2 className="d-flex flex-column flex-sm-row my-4 my-lg-5 theme-secundary">SOBRE A <span className="theme-primary ml-sm-2">DROGALESTE</span></h2>
              <p>
              Há 30 anos, no extremo leste de São Paulo abrimos a primeira farmácia. Hoje, temos o orgulho de ser uma das melhores redes de drogarias não só da zona leste de São Paulo, mas também com lojas na zona norte, Guarulhos, Santo André e Itaquá. São lojas modernas, com atendimento personalizado, grande variedade de produtos e entregas rápidas, como nas farmácias de bairro. E tudo sempre com o melhor preço possível. Conheça uma de nossas lojas e surpreenda-se.</p>
              <Link to="/quem-somos/" title="Leia mais sobre a nossa empresa" className="d-flex justify-content-center align-items-center mt-sm-4 btn theme-border-2 theme--primary text-white w-100">LEIA MAIS SOBRE A NOSSA EMPRESA</Link>
            </div>
          </div>
        </div>
      </section>
      <SectionBlogPosts/>
      <FormIndex />
    </Fragment>
  )
}
